import { ButtonCc2r } from "../../components/Button";
import { S_Div, S_Title } from "./style";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyIcon from "@mui/icons-material/Key";
import { UserContext } from "../../utils/context";
import { useContext, useState } from "react";
import { Oval } from "react-loader-spinner";

const Login = () => {
  const { changeUserState } = useContext(UserContext);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    let formData = new FormData(e.target);

    fetch("https://cameras.cc-deuxrives.fr/users/login", {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setIsLoading(false);
        if (json.access === "granted") {
          changeUserState(json);
        } else {
          setIsFailed(true);
        }
      });
  };

  return (
    <S_Div onSubmit={handleSubmit}>
      <Oval
        wrapperClass="spinner"
        height={80}
        width={80}
        color="#e66c86"
        visible={isLoading}
        ariaLabel="oval-loading"
        secondaryColor="#29a78c"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <S_Title>Connexion</S_Title>
      <div
        className="login-wrapper"
        style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
      >
        <TextField
          error={isFailed}
          name="username"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="standard"
          label="Nom d'utilisateur"
          helperText={isFailed ? "Identifiants érronés" : null}
          required
        ></TextField>
        <TextField
          error={isFailed}
          name="password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <KeyIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
          type="password"
          label="Mot de passe"
          // helperText="Mot de passe erroné"
          required
        ></TextField>
      </div>
      <div
        className="validation-btn"
        style={isLoading ? { opacity: 0.5 } : { opacity: 1 }}
      >
        <ButtonCc2r style={{ height: "48px" }} type="submit" variant="green">
          Valider
        </ButtonCc2r>
      </div>
    </S_Div>
  );
};

export default Login;
