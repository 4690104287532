import styled from "styled-components";

export const S_Nav = styled.div`
  margin-bottom: 10px;
  position: relative;
  height: 90px;
  box-shadow: 2px 3px 21px 0px rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
  width: 100%;

  &:before {
    content: "";
    left: -14px;
    position: absolute;
    width: 62px;
    height: 34px;
    background: linear-gradient(90deg, #6cc9e7 0%, #0d3fc4 100%);
    -webkit-mask-image: url(/images/vague-masque.png);
    mask-image: url(/images/vague-masque.png);
    transform: rotate(90deg);
  }

  .login-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    gap: 15px;
    margin-right: 15px;
  }

  .username {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    .login-wrapper {
      margin-right: 0;
      justify-content: center;
      width: 100%;
    }
  }

  @media screen and (max-width: 400px) {
    .login-wrapper {
      justify-content: right;
      padding-right: 15px;
      button {
        width: 120px;
      }
    }
  }
`;

export const S_NavLinks = styled.nav`
  display: flex;
`;

export const S_Title = styled.h1`
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  margin-left: 42px;
  width: 50%;
  font-size: 2.5rem;

  @media screen and (max-width: 1200px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 992px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 576px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width: 400px) {
    text-align: right;
    padding-right: 15px;
    font-size: 1.2rem;
  }
`;
