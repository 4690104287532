import { createGlobalStyle } from "styled-components";
import colors from "./colors";

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}

#root {
    margin: auto;
    max-width: 1920px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 100vh;
  }

body {
  // overflow: hidden;
  margin: 0 auto;
  color: #393939;
  -webkit-font-smoothing: auto;
  font-family: "lato", sans-serif;
}

h1 {
  font-size: 3.5rem;
  font-weight: 900;
  margin: 0;
}

// Customize calendar
.rs__outer_table {
  > div {
    > div {
      overflow: overlay;
    } 
  }    
} 


// Customize event-popup
.MuiPopover-root.MuiModal-root{

  &:not(.MuiMenu-root, .MuiTelInput-Menu){
    > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper{
      top: 250px !important;
      max-height: calc(100% - 250px);
    } 
  
    span.MuiTypography-root.MuiTypography-caption.MuiTypography-noWrap{
      display: none !important;
    } 

    p.MuiTypography-root{
      display: none;
    } 

  } 






  .viewer-extra {
    img{
      width: 100%
    } 
  }
} 

.notifications{
  width: 80%;
  margin-left: 15px;
  margin-top: 25px;
} 

.date-form {
    margin-top: 20px;
    > div{
      margin-top: 10px;
      margin-right: 5px;
    } 
  }

  .home-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 15;
    background: white;
    svg {
      position: relative;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
    }
  }
`;

export default GlobalStyle;
