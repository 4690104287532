import FullCalendar from "@fullcalendar/react";
import { createRef, useState } from "react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { fr } from "date-fns/locale";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import frLocale from "@fullcalendar/core/locales/fr";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Oval } from "react-loader-spinner";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useContext } from "react";
import { S_Div } from "./style";
import { UserContext } from "../../utils/context";
import { format } from "date-fns";
import { Switch, FormGroup, FormControlLabel } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const Home = () => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState({ start: "", end: "" });
  const [sequence, setSequence] = useState();
  const { user, changeUserState } = useContext(UserContext);
  const [idBat, setIdBat] = useState(user.id_batiment);
  const [eventAction, setEventAction] = useState(false);
  const [rec, setRec] = useState(false);
  const [week, setWeek] = useState(1);

  const [eventsList, setEventsList] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadEvents();
    let calendar = calendarRef?.current?.getApi();
    calendar?.unselect();
    setIdBat(user.id_batiment);
  }, [user]);

  useEffect(() => {
    let calendar = calendarRef?.current?.getApi();
    calendar?.unselect();
  }, [eventsList, user]);

  const createEvent = async () => {
    setIsLoading(true);
    let formData = new FormData();

    formData.set("date_event", format(date.start, "yyyy-MM-dd"));
    formData.set("debut", format(date.start, "HH:mm:SS"));
    formData.set("fin", format(date.end, "HH:mm:SS"));
    formData.set("sequence_jour", sequence);
    formData.set("id_batiment", idBat);
    formData.set("recurrence", rec);
    if (rec && week > 0) formData.set("weeks", week);

    fetch(
      `https://cameras.cc-deuxrives.fr/events/${
        eventAction ? "update" : "create"
      }`,
      {
        method: "POST",
        body: formData,
        credentials: "include",
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.event === "created" || data.event === "updated") {
          let calendar = calendarRef?.current?.getApi();
          calendar?.unselect();
          loadEvents();
          handleClose();
        }
      });
  };

  const deleteEvent = async () => {
    setIsLoading(true);
    let formData = new FormData();

    formData.set("date_event", format(date.start, "yyyy-MM-dd"));
    formData.set("debut", format(date.start, "HH:mm:SS"));
    formData.set("fin", format(date.end, "HH:mm:SS"));
    formData.set("sequence_jour", sequence);
    formData.set("id_batiment", idBat);

    fetch(`https://cameras.cc-deuxrives.fr/events/delete`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.event === "deleted") {
          loadEvents();
          handleClose();
          let calendar = calendarRef?.current?.getApi();
          calendar?.unselect();
        }
      });
  };

  const loadEvents = async () => {
    setIsLoading(true);
    return fetch("https://cameras.cc-deuxrives.fr/events/list", {
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.length || data.length === 0) {
          const upEvents = [];

          data?.map((data) => {
            upEvents.push(data);
          });

          upEvents.sort(function (a, b) {
            return +a.id_event - +b.id_event;
          });

          let sortEvents = [];

          console.log(upEvents);
          upEvents.map((event, i) => {
            if (
              i % 2 === 0 &&
              event.date_event === upEvents[i + 1].date_event &&
              event.sequence_event === upEvents[i + 1].sequence_event &&
              event.type_event != upEvents[i + 1].type_event
            ) {
              sortEvents.push({
                start: `${event.date_event} ${event.heure_event}`,
                end: `${upEvents[i + 1].date_event} ${
                  upEvents[i + 1].heure_event
                }`,
                extendedProps: {
                  seq: event?.sequence_event,
                },
              });
            }
          });
          setIsLoading(false);
          setEventsList(sortEvents);
        }
        let calendar = calendarRef?.current?.getApi();
        calendar?.unselect();
        setIsLoading(false);
      });
  };

  const handleWeek = (value) => {
    console.log(value);
    setWeek(value);
  };

  const handleRecChange = () => {
    setRec((prev) => !prev);
  };

  const handleChangeDate = (value, name) => {
    setDate((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setWeek(1);
    setRec(false);
  };

  const handleClose = () => {
    setOpen(false);
    let calendar = calendarRef?.current?.getApi();
    calendar?.unselect();
  };

  const selectDate = (arg, edit = false) => {
    setEventAction(edit ? true : false);
    setDate({
      start: arg.start,
      end: arg.end,
    });

    setSequence(arg?.extendedProps?.seq);
    let calendar = calendarRef.current.getApi();
    handleClickOpen();
  };

  const eventClicks = (arg) => selectDate(arg.event, true);

  let calendarRef = createRef();

  return (
    <S_Div>
      <Oval
        wrapperClass="spinner"
        height={80}
        width={80}
        color="#e66c86"
        visible={isLoading}
        ariaLabel="oval-loading"
        secondaryColor="#29a78c"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <FullCalendar
        ref={calendarRef}
        selectOverlap={false}
        plugins={[interactionPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
        firstDay={1}
        eventAllow={() => false}
        selectable={true}
        allDaySlot={false}
        locale={frLocale}
        editable={true}
        eventClick={eventClicks}
        select={selectDate}
        selectMirror={true}
        events={eventsList}
        unselectAuto={false}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ajouter une plage horaire</DialogTitle>
        <DialogContent>
          <DialogContentText>Ouverture du Bâtiment</DialogContentText>

          <div className="date-form">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={fr}
            >
              <DateTimePicker
                value={date.start}
                renderInput={(props) => <TextField {...props} />}
                label="Début"
                onChange={(value) => handleChangeDate(value, "start")}
              />
              <DateTimePicker
                value={date.end}
                renderInput={(props) => <TextField {...props} />}
                label="Fin"
                onChange={(value) => handleChangeDate(value, "end")}
              />
            </LocalizationProvider>
          </div>
          {!eventAction && (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <>
                      <Switch
                        name="recurrence"
                        color="success"
                        checked={rec}
                        onChange={handleRecChange}
                      />
                    </>
                  }
                  label="Récurrent"
                />
              </FormGroup>
              {rec && (
                <>
                  <label htmlFor="">Nombre de Semaines</label>
                  <input
                    name="maxNodeSelectedCount"
                    component={TextField}
                    label="Max Node Selected Count"
                    type="number"
                    min={1}
                    max={52}
                    required
                    onChange={(e) => handleWeek(e.target.value)}
                    value={week}
                  />
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {eventAction && (
            <Button
              className="button-sup"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={deleteEvent}
            >
              Supprimer
            </Button>
          )}
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={createEvent}>Valider</Button>
        </DialogActions>
      </Dialog>
    </S_Div>
  );
};

export default Home;
