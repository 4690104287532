import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
import Admin from "./pages/Admin";
import Error from "./pages/Error";
import Home from "./pages/Home";
import Login from "./pages/Login";
import GlobalStyle from "./styles/GlobalStyle";
import { UserContext } from "./utils/context";
import { useContext, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, changeUserState } = useContext(UserContext);
  const isAuth = () => {
    fetch("https://cameras.cc-deuxrives.fr/users/is_auth", {
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.access === "granted") {
          changeUserState(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    isAuth();
  }, []);

  useEffect(() => {}, [user]);

  return (
    <>
      <GlobalStyle />
      <Header />
      <NavBar title={user.batiment} />
      <Oval
        wrapperClass="home-spinner"
        height={80}
        width={80}
        color="#e66c86"
        visible={isLoading}
        ariaLabel="oval-loading"
        secondaryColor="#29a78c"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
      <Routes>
        <Route
          path="/"
          element={user.access === "granted" ? <Home /> : <Login />}
        />
        <Route
          path="/admin"
          element={
            user.access === "granted" && user.role === "administrateur" ? (
              <Admin />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
