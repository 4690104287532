import { S_Button, S_Input, S_Link } from './style'

const ButtonCc2r = ({ children, style, variant, onClick, type }) => {
  return (
    <S_Button type={type} onClick={onClick} style={style} variant={variant}>
      {children}
    </S_Button>
  )
}

const InputCc2r = ({ variant, name, accept, type, style }) => {
  return (
    <S_Input
      type={type}
      name={name}
      accept={accept}
      variant={variant}
      style={style}
    />
  )
}

const NavLinkCc2r = ({ children, style, variant, onClick, type }) => {
  return (
    <S_Link type={type} onClick={onClick} style={style} variant={variant}>
      {children}
    </S_Link>
  )
}

export { ButtonCc2r, InputCc2r, NavLinkCc2r }
