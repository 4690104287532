import { useState, createContext } from 'react'

export const UserContext = createContext()

/**
 * @returns {ReactContextProvider}
 */
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const changeUserState = (data) => {
    setUser((user) => data)
  }

  return (
    <UserContext.Provider value={{ user, changeUserState }}>
      {children}
    </UserContext.Provider>
  )
}
