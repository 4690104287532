import { S_HEADER, S_LINEAR_BG, S_LOGO } from "./style";
import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Header = () => {
  const { width } = useWindowDimensions();
  return (
    <S_HEADER>
      <S_LOGO
        src={
          width > 768 ? "/images/CC2R.BLANC.png" : "/images/logo-cc2R-seul.png"
        }
        alt="logo de la communauté de communes des deux rives"
      />
      <S_LINEAR_BG />
    </S_HEADER>
  );
};

export default Header;
