import { ButtonCc2r, NavLinkCc2r } from "../Button";
import { S_Nav, S_Title, S_NavLinks } from "./style";
import { UserContext } from "../../utils/context";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const NavBar = ({ title }) => {
  const { user, changeUserState } = useContext(UserContext);
  const [batList, setBatList] = useState([]);
  const location = useLocation();
  const [bat, setBat] = useState(user.id_batiment);

  const isAuth = () => {
    fetch("https://cameras.cc-deuxrives.fr/users/is_auth", {
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.access === "granted") {
          changeUserState(data);
        }
      });
  };

  useEffect(() => {}, [user]);

  const handleChangeBat = (event) => {
    setBat(event.target.value);

    let formData = new FormData();

    let batObj = batList.find((bati) => {
      if (bati.id_batiment == event.target.value) return bati.batiment;
    });

    formData.set("id_batiment", event.target.value);
    formData.set("batiment", batObj.batiment);

    fetch(`https://cameras.cc-deuxrives.fr/users/change_batiment`, {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.batiment === "changed") {
          isAuth();
        }
      });
  };

  const listBat = () => {
    fetch("https://cameras.cc-deuxrives.fr/users/list_batiment_user", {
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data) {
          setBatList(data);
        }
      });
  };

  useEffect(() => {
    if (user.access === "granted") {
      listBat();
    }
  }, [user]);

  const handleLogout = () => {
    fetch("https://cameras.cc-deuxrives.fr/users/logout", {
      credentials: "include",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.access === "disconnected") {
          changeUserState({});
        }
      });
  };

  return (
    <>
      {" "}
      {user.access === "granted" && (
        <>
          <S_Nav>
            <S_Title>{title}</S_Title>
            <div className="login-wrapper">
              {batList.length > 1 && (
                <FormControl fullWidth>
                  <InputLabel id="bat">Bâtiment</InputLabel>
                  <Select
                    labelId="bat"
                    id="batSelect"
                    value={bat ?? user.id_batiment}
                    label="Bâtiment"
                    onChange={handleChangeBat}
                  >
                    {batList.map((bat, i) => (
                      <MenuItem key={i} value={+bat.id_batiment}>
                        {bat.batiment}{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {user.access === "granted" && (
                <div className="username">{user.user}</div>
              )}
              {user.access === "granted" && (
                <ButtonCc2r onClick={handleLogout}>Déconnexion</ButtonCc2r>
              )}
            </div>
          </S_Nav>
          <S_NavLinks>
            {location.pathname !== "/admin" && user.role === "administrateur" && (
              <Link style={{ textDecoration: "none" }} to={`/admin`}>
                <NavLinkCc2r>Dashboard</NavLinkCc2r>
              </Link>
            )}
          </S_NavLinks>
        </>
      )}
    </>
  );
};

export default NavBar;
