import styled from "styled-components";

export const S_Div = styled.div`
  .spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 662px;
    z-index: 15;

    svg {
      position: relative;
      left: calc(50% - 40px);
      top: calc(50% - 40px);
    }
  }
`;
