import styled from "styled-components";

const type = {
  orange: "#e66c86 0%,#f19a5d 51%,#e66c86 100%",
  green: "#6cc9e7 0%, #0d3fc4 100%",
};

export const S_Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  max-width: 150px;
  background-image: linear-gradient(
    90deg,
    ${(props) => (props.variant ? type[props.variant] : type.orange)}
  );
  cursor: pointer;
  background-size: 200% auto;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.6875em;
  font-weight: 900;
  letter-spacing: 0.15em;
  padding: 9px 30px;
  vertical-align: bottom;
  transition: all 0.5s ease-in-out;
  box-shadow: 1px 2px 6px 0px rgb(0 0 0 / 35%);

  &:hover {
    text-decoration: none !important;
    background-position: right center;
  }
`;

export const S_Link = styled.button`
  cursor: pointer;
  display: block;
  white-space: nowrap;
  padding: 0;
  border: none;
  background: transparent;
  height: 100%;
  font-size: 1.0625rem;
  border-right: 1px solid #d5d5d5;
  padding: 6px 23px;
  margin: 0;
  position: relative;
  font-weight: 900;
  font-size: 0.9375rem;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s ease-in-out;
  -webkit-appearance: button;
  overflow: visible;
  font-family: inherit;
  line-height: inherit;
  border-radius: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #ec876e;
  }

  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 25px);
    bottom: -44px;
    width: 52px;
    height: 0px;
    background: linear-gradient(90deg, #e66c86 0%, #f19a5d 100%);
    mask-image: url("/images/vague-masque.png");
    -webkit-mask-image: url("/images/vague-masque.png");
    transition: all 0.2s ease-in-out;
  }
`;

export const S_Input = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background-image: linear-gradient(
    90deg,
    ${(props) => (props.variant ? type[props.variant] : type.orange)}
  );
  cursor: pointer;
  background-size: 200% auto;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.6875em;
  font-weight: 900;
  letter-spacing: 0.15em;
  padding: 9px 30px;
  vertical-align: bottom;
  transition: all 0.5s ease-in-out;
  box-shadow: 1px 2px 6px 0px rgb(0 0 0 / 35%);

  &:hover {
    text-decoration: none !important;
    background-position: right center;
  }
`;
